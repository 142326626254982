import { computed, reactive, ref, set, watch } from 'vue'
import { pageStartIndex as startIndex } from '@/utils/table'
import dayjs from '@/lib/dayjs'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { compact, omitBy, omit, get } from 'lodash'

export const useTable = () => {
  const router = useRouter()
  const route = useRoute()
  const selectRow = ref(null)
  const tableData = ref([])
  const tableDataCount = ref(0)
  const tableOptions = reactive({
    page: 1,
    pageLimit: 10,
    dataCount: 0,
  })
  const search = reactive({})
  const dialog = reactive({
    delete: false,
    edit: false,
  })
  const loading = reactive({
    table: false,
  })

  const shopId = computed(() => store.getters.shop)

  const updateRouteQuery = (queryData) => {
    router.push({
      name: route.name,
      query: queryData,
      replace: true
    })
  }

  const syncRouteQuery = (search, tableOptions, customHandler) => {
    if (customHandler) {
      customHandler()
      return
    }
    const query = route.query
    const searchQuery = omit(query, ['page'])
    tableOptions.page = Number(query.page)

    for (const key in searchQuery) {
      const queryValue = get(searchQuery, key)
      if (queryValue) set(search, key, queryValue)
    }
  }

  const extendData = (primaryKey, newData) => {
    for (const attr in newData) {
      if (primaryKey === 'search') set(search, attr, newData[attr])
      else if (primaryKey === 'dialog') set(dialog, attr, newData[attr])
      else if (primaryKey === 'loading') set(loading, attr, newData[attr])
    }
  }
  const pageStartIndex = computed(() => {
    return startIndex(tableOptions.page, tableOptions.pageLimit)
  })

  const dateFormat = (date, format = 'YYYY/MM/DD HH:mm') => {
    return dayjs(date).format(format)
  }

  const fetchData = async (apiFunc, payload) => {
    const [res, err] = await apiFunc(payload)
    if (err) return window.$message.error(err)
    tableData.value = res
  }
  const fetchDataCount = async (apiFunc, payload) => {
    const [res, err] = await apiFunc(payload)
    if (err) return window.$message.error(err)
    tableDataCount.value = res.count
  }

  return {
    shopId,
    pageStartIndex,
    dateFormat,
    extendData,
    selectRow,
    tableData,
    tableDataCount,
    tableOptions,
    search,
    dialog,
    loading,
    fetchData,
    fetchDataCount,
    updateRouteQuery,
    syncRouteQuery,
  }
}
