<template>
  <div class="coupon-exchange-setting-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column prop="name" label="圖片" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto"
            width="90"
            :src="
              scope.row.Image ? imgSource(scope.row.Image) : ThumbnailDefault
            "
          >
        </template>
      </el-table-column>
      <el-table-column label="名稱" prop="name" align="center" />
      <el-table-column label="領取後可使用天數" prop="expDay" align="center" />
      <el-table-column label="特定截止日期" prop="expAt" align="center" />
      <el-table-column label="剩餘數量" fixed="right" width="120" prop="lastCount" align="center" />
      <el-table-column label="兌換碼管理" fixed="right" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="btn edit"
            @click="
              $router.push({
                name: 'CouponExchangeSerial',
                params: { id: get(scope.row, 'id') },
              })
            "
          >
            管理
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120" align="center" prop="">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteCouponExchange"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import { get, map, isNull } from 'lodash'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { formatDate } from '@/utils/date'
import ThumbnailDefault from '@/assets/coupon/thumbnail_default.svg'
import { imgSrc } from '@/utils/helper'
import { DeleteCouponExchange } from '@/api/coupon'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'

export default defineComponent({
  name: 'CouponExchangeSettingTable',
  components: { DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, Image, name, expDay, expAt, sentLimit, sentCount } = item
        return {
          id,
          Image,
          name,
          expDay: expDay ? `${expDay} 天` : ' - ',
          expAt: expAt ? formatDate(expAt, 'YYYY/MM/DD HH:mm:ss') : ' - ',
          lastCount: !isNull(sentLimit) && !isNull(sentCount) ? sentLimit - sentCount : ' - ',
        }
      })
    })
    const imgSource = (image) => {
      return imgSrc(90, image || null)
    }

    const deleteCouponExchange = async () => {
      const [, err] = await DeleteCouponExchange({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const onEdit = (row) => {
      const { id } = row
      router.push({
        name: 'CouponExchangeCreate',
        params: { id },
      })
    }

    return {
      ThumbnailDefault,
      displayData,
      imgSource,
      modal,
      deleteCouponExchange,
      onDelete,
      onEdit,
      get,
    }
  },
})
</script>

<style lang="postcss" scoped>
.btn {
  @apply underline text-normal font-normal;
}
</style>
